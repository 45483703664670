
import { computed, defineComponent, onMounted } from "vue";
import store from "@/store/index";
import { User } from "@/store/modules/AuthModule";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";

export default defineComponent({
  name: "user-security",
  setup: function() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const user = computed((): User => store.getters.currentUser);

    onMounted((): void => {
      const routeId = route.params.id as string;
      if (user.value?.id !== parseInt(routeId)) {
        router.push("/");
      }
    });

    return {
      user
    };
  }
});
